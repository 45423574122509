import { Link, SxProps, Theme } from "@mui/material";
import React, { FunctionComponent, PropsWithChildren } from "react";
import { colors } from "../themes/colors";

export type ExternalLinkProps = PropsWithChildren<{
  href: string;
  title?: string;
  sx?: SxProps<Theme> | undefined;
}>;

export const ExternalLink: FunctionComponent<ExternalLinkProps> = ({
  href,
  title,
  children,
  sx = {},
}) => {
  return (
    <Link
      href={href}
      title={title}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        color: colors.PURPLE_MEDIUM,
        textDecoration: "none",
        ...sx,
      }}
    >
      {children}
    </Link>
  );
};
