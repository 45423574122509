import React, { FunctionComponent, PropsWithChildren } from "react";
import { CircularProgress, Stack } from "@mui/material";

export interface LoaderProps {
  loading?: boolean;
}

export const Loader: FunctionComponent<PropsWithChildren<LoaderProps>> = ({
  loading,
  children,
}) =>
  loading ? (
    <Stack alignItems="center">
      <CircularProgress />
    </Stack>
  ) : (
    children
  );
