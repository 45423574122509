import { AxiosResponse } from "axios";
import axios, { AxiosInstance } from "axios";

export type ClientConfig = {
  baseURL?: string;
  qpTerritory: string;
};

export type RestClient = {
  client: AxiosInstance;
  // eslint-disable-next-line
  setRequestInterceptor: (token: string, cb?: () => void) => void;
  setResponseInterceptor: (
    // TODO: fix any
    // eslint-disable-next-line
    onFulfulled: (response: AxiosResponse<any, any>) => AxiosResponse<any, any>,
    // TODO: fix any
    // eslint-disable-next-line
    onRejected: (error: any) => any,
  ) => void;
};

export function createClient(config: ClientConfig): RestClient {
  const client = axios.create({ baseURL: config.baseURL || "" });

  async function setRequestInterceptor(token: string, cb?: () => void) {
    client.interceptors.request.use(
      async function (config) {
        if (!config.headers) config.headers = {};
        // @ts-ignore
        config.headers.authorization = `Bearer ${token}`;
        // @ts-ignore
        config.headers["qp-territory"] = config.qpTerritory;
        return config;
      },

      function (err) {
        return Promise.reject(err);
      },
    );
    cb?.();
  }

  async function setResponseInterceptor(
    // eslint-disable-next-line
    onFulfilled: (response: AxiosResponse<any, any>) => AxiosResponse<any, any>,
    // TODO: fix any
    // eslint-disable-next-line
    onRejected: (error: any) => any,
  ) {
    client.interceptors.response.use(onFulfilled, onRejected);
  }

  return { client, setRequestInterceptor, setResponseInterceptor };
}
