import { MenuItem } from "@mui/material";
import { FunctionComponent } from "react";
import { useSession } from "../hooks/useSession";

export const LogoutMenuItem: FunctionComponent = () => {
  const { logout } = useSession();

  return (
    <MenuItem>
      <a onClick={() => logout()}>Logout</a>
    </MenuItem>
  );
};
