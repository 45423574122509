import { Paper } from "@mui/material";
import styled, { AnyStyledComponent, StyledComponent } from "styled-components";

export const StyledPaper: StyledComponent<
  AnyStyledComponent,
  React.ComponentType
> = styled(Paper)`
  margin-bottom: 1rem;
  borderradius: 7px;
  boxshadow: none;
  width: 100%;
  max-width: calc(100vw - 64px);

  @media (min-width: 882px) {
    max-width: calc(100vw - 340px);
  }
`;
